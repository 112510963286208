import { defineStore } from "pinia";

export const usePermissionsStore = defineStore("permissions", {
  state: (): {
    permissions: { address: string; role: string }[];
  } => ({
    permissions: [],
  }),
  actions: {
    async getRiseIDPermissions(nanoid: string) {
      const response = await this.$client[
        "/dashboard/permissions/riseid/{nanoid}/wallets"
      ].get({ params: { nanoid } });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      this.permissions = responseJson.data.wallets;
      return responseJson.data.wallets;
    },
    async postAddRiseIDPermission(
      nanoid: string,
      wallet: string,
      role: "payer" | "viewer" | "none" | "owner" | "treasurer"
    ) {
      const response = await this.$client[
        "/dashboard/permissions/riseid/{nanoid}/wallets/{wallet}/role/{role}"
      ].post({
        params: { nanoid, wallet, role },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      return responseJson.data;
    },
    async putAddRiseIDPermission(
      nanoid: string,
      wallet: string,
      role: "payer" | "viewer" | "none" | "owner" | "treasurer",
      json: any
    ) {
      const response = await this.$client[
        "/dashboard/permissions/riseid/{nanoid}/wallets/{wallet}/role/{role}"
      ].put({
        params: { nanoid, wallet, role },
        json,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      return responseJson.data;
    },
  },
});

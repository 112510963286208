import { ethers } from "ethers";
import { defineStore } from "pinia";
import { useWeb3 } from "web3";
import { RisePayToken_arbitrum__factory } from "~/.nuxt/contracts/src/codegen/abi_types/factories/RisePayToken_arbitrum__factory";

const web3 = useWeb3();
const runtimeConfig = useRuntimeConfig();
const provider = new ethers.JsonRpcProvider(
  runtimeConfig.public.RPC_URL as string
);

const factories = {
  RisePayToken: RisePayToken_arbitrum__factory,
};

export const useWeb3Store = defineStore("web3", {
  state: (): {
    connectModal: boolean;
    signData: boolean;
    balance: number;
    reattempt: boolean;
  } => ({
    connectModal: false,
    signData: false,
    balance: 0,
    reattempt: false,
  }),
  actions: {
    mint(account: string) {
      this.$client["/dashboard/accounts/balance/mint"].post({
        json: {
          amount: "100000000000000000000000",
          account,
          token: "USDC",
        },
      });
    },
    async connect() {
      return new Promise((resolve, reject) => {
        const listenerFunction = async (event: MessageEvent) => {
          const permissionsStore = usePermissionsStore();
          const address = await this.getAddress();
          if (
            ["rsk-connect-response", "connect-response"].includes(
              event.data.type
            )
          ) {
            if (address) {
              if (
                !permissionsStore.permissions.some(
                  (e) => e.address.toLowerCase() === address.toLowerCase()
                )
              ) {
                this.reattempt = true;
              } else {
                window.removeEventListener("message", listenerFunction);
                resolve(true);
              }
            } else {
              window.removeEventListener("message", listenerFunction);
              resolve(true);
            }
          }
          if (["web3modal-interrupted"].includes(event.data.type)) {
            window.removeEventListener("message", listenerFunction);
            reject(new Error("User denied connection request"));
          }
        };
        window.addEventListener("message", listenerFunction);
        this.connectModal = true;
      });
    },
    async contract(name: keyof typeof factories, address: string) {
      return factories[name].connect(address);
    },
    async getAddress() {
      try {
        const address = await web3.getAddress();
        return address;
      } catch {
        return null;
      }
    },
    async getBalance(address: string) {
      const runtimeConfig = useRuntimeConfig();
      const risePayToken = await factories.RisePayToken.connect(
        runtimeConfig.public.RISEUSD_ADDRESS as string,
        provider
      );
      console.log(
        `Fetching balance of token ${
          runtimeConfig.public.RISEUSD_ADDRESS as string
        } on address ${address}`
      );
      const cents =
        Number(await risePayToken["balanceOf(address)"](address)) /
        10 ** Number(await risePayToken.decimals());
      this.balance = parseFloat(cents.toString());
      console.log("Balance", this.balance);
      return this.balance;
    },
    async signTypedData(payload: any) {
      const address = await web3.getAddress();
      if (!address) {
        await this.connect();
      }
      return web3.signTypedData(payload);
    },
  },
});
